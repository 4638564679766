export default [
  {
    field: 'totalInventory',
    text: 'Número total de piezas en EXISTENCIA en el Inventario.',
  },
  {
    field: 'totalGDL',
    text: 'Número de piezas disponibles en Inventario registradas con ubicación Bodega GDL.',
  },
  {
    field: 'totalCDMX',
    text: 'Número de piezas disponibles en Inventario registradas con ubicación Bodega CDMX.',
  },
  {
    field: 'totalStore',
    text: 'Número de piezas disponibles en Inventario registradas con ubicación Tienda.',
  },
  {
    field: 'totalPresale',
    text: 'Número de piezas disponibles en Inventario registradas con disponibilidad Preventa.',
  },
  {
    field: 'totalPiecesSoldPresale',
    text: 'Número de piezas con disponibilidad Preventa en estatus Solicitud u Orden en el módulo de Entradas.',
  },
  {
    field: 'totalOnWay',
    text: 'Número de piezas de la suma EN CAMINO DISPONIBLES + VENDIDO PREVENTA.',
  },
  {
    field: 'totalInOrder',
    text: 'Número de piezas con disponibilidad Disponible en estatus Solicitud u Orden en el módulo de Entradas.',
  },
  {
    field: 'totalSold',
    text: 'Número de piezas con disponibilidad Disponible o Preventa en estatus Entregado en el módulo de Entradas.',
  },
  {
    field: 'totalReturned',
    text: 'Número de piezas que están dentro de una Devolución Recibida y se fueron devueltas al Inventario.',
  },
  {
    field: 'PriceMin',
    text: 'Precio mínimo registrado del producto.',
  },
  {
    field: 'totalSale',
    text: 'Cantidad resultante de multiplicar el Precio Mínimo registrado del producto x número de piezas en Existencias de Equipos + En camino Disponibles.',
  },
  {
    field: 'ProvidersPriceUsd',
    text: 'Costo proveedor registrado del producto.',
  },
  {
    field: 'totalCost',
    text: ' Cantidad resultante de multiplicar el Costo proveedor registrado del producto x número de piezas en Existencias de Equipos + En camino Disponibles.',
  },
]
