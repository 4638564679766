<template>
  <div
    class="mt-2"
  >
    <!-- #region::Skeleton -->
    <template v-if="isLoading">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #endregion::Skeleton -->
    <validation-observer
      v-if="!isLoading && !aviableInfo"
      ref="sellerForm"
    >

      <!-- #region::Form -->
      <b-form
        class="border rounded"
        style="background:white; border-color: #b7b7b7;"
      >
        <b-form-row>

          <!-- #region::Title and close button -->
          <b-col
            md="12"
            class="align-self-center hero-change"
          >
            <div class="d-flex justify-content-between">
              <h3 class="align-self-end">
                Tipo de cambio registrado (USD a MXN)
              </h3>
              <!-- #region::Cancel save button -->
              <b-col
                v-if="exchange && upExchange"
                md="auto"
                class="ml-auto"
              >
                <b-button
                  variant="delete-btn"
                  class="my-lg-0 w-100 delete-btn"
                  @click="onClose"
                >
                  Cancelar
                </b-button>
              </b-col>
              <!-- #endregion::Cancel save button -->
              <!-- #region::Seller save button -->
              <b-col
                v-if="exchange"
                md="auto"
                class="px-0"
              >
                <b-button
                  variant="principal-btn"
                  class="my-1 my-md-0 w-100 principal-btn"
                  @click="upExchange? onSaveSeller() : onUpdate()"
                >
                  {{ upExchange ? 'Guardar cambios' : 'Editar' }}
                </b-button>
              </b-col>
              <!-- #endregion::Seller save button -->

            </div>
          </b-col>
          <!-- #endregion::Title and close button -->

          <b-col
            md="12"
          >
            <hr
              class="m-0"
              style="margin-bottom:8px!important"
            >
          </b-col>
          <div
            v-if="noExchange && !exchange"
            class="no-change w-100 body-padding-change"
          >
            <feather-icon
              icon="DollarSignIcon"
              class="image-icon"
            />
            <h4>Sin tipo de cambio registrado</h4>
            <b-col md="auto">
              <b-button
                variant="principal-btn"
                class="my-1 my-md-0 w-100 principal-btn btn-add-change"
                @click="onUpdate"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="image-icon w-25 h-25"
                />
                Agregar tipo de cambio
              </b-button>
            </b-col>
          </div>
          <div
            v-if="exchange && !upExchange"
            class="box-change w-100 body-padding-change"
          >
            <div class="box-flag">
              <img
                :src="flagUsd"
                alt="bandera de EUA"
                class="rounded"
                height="52"
              >
              <div class="box-flag-text ml-1">
                <p class="mb-0">
                  $1.00 Dólar estadounidense equivale a
                </p>
                <h1 class="mb-0">
                  {{ validateCurrency(USD) }} Pesos Mexicanos
                </h1>
              </div>
            </div>

          </div>
          <div
            v-if="exchange && upExchange"
            md="11"
            class="w-100 d-flex body-padding-change"
          >
            <!-- #region::Seller name input -->
            <b-col md="5">
              <TextInputWithValidation
                v-model="MXN"
                vid="MXN"
                type="text"
                label="Indica la cantidad"
                name="money_mxn"
                placeholder="$1.00"
                :disabled="true"
              />
            </b-col>
            <!-- #endregion::Seller name input -->
            <b-col
              md="1"
              class="text-center icon-equal"
            >
              <i class="fa-solid fa-equals" />
            </b-col>
            <!-- #region::Enterprise input -->
            <b-col
              md="5"
              class=""
            >
              <TextInputWithValidation
                v-model="USD"
                vid="USD"
                :rules="{required, min:2, regex: /^\d{1,2}(\.\d{1,2})?$/}"
                type="text"
                label="Indica la cantidad"
                name="Dollar"
                maxlength="200"
                placeholder="$1.00"
              />
            </b-col>
          <!-- #endregion::Enterprise input -->
          </div>

        </b-form-row>
      </b-form>
      <!-- #endregion::Form -->

    </validation-observer>
    <!-- #region::Alert when no transfers are available -->
    <b-alert
      v-if="aviableInfo"
      variant="warning"
      class="my-0"
      show
    >
      <div class="alert-body">
        <span>No se encontraron datos.</span>
      </div>
    </b-alert>
  <!-- #endregion::Alert when no transfers are available -->
  </div>
</template>

<script>
// #region imports
import { mapActions } from 'vuex'
import {
  required,
} from '@validations'
import {
  BFormRow, BCol, BForm, BButton, BAlert, BSkeleton,
} from 'bootstrap-vue'

import { ValidationObserver, localize } from 'vee-validate'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import es from 'vee-validate/dist/locale/es.json'

import getError from '@/helpers/ErrorsHandler'
import flagUsd from '@/assets/images/flags/en.png'
// #endregion

export default {
  components: {
    BCol,
    BForm,
    BSkeleton,
    BButton,
    BAlert,
    BFormRow,
    ValidationObserver,
    TextInputWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      MXN: this.validateCurrency(1),
      USD: '',
      // * 05/10/2022 - TAG: Validations
      required,
      isLoading: false,
      flagUsd,
      noExchange: false,
      exchange: false,
      upExchange: false,
      aviableInfo: false,
      statePrice: 0,
    }
  },
  computed: {},
  watch: {
    // * 05/10/2022 - TAG: Watchers
    USD(value) {
      this.USD = this.formatPrice(value.replace(/,/g, ''))
    },
  },
  async created() {
    localize('es', es)
    await this.loadExchange()
  },
  methods: {
    ...mapActions({
      getExchange: 'ecommerce/getExchange',
      updateExchange: 'ecommerce/updateExchange',
    }),
    async loadExchange() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoading = true

        const response = await this.getExchange()
        this.isLoading = false

        this.USD = this.listenerData(response)
        this.$swal.close()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
        this.isLoading = false
        this.aviableInfo = true
      }
    },
    async onSaveSeller() {
      const success = await this.$refs.sellerForm.validate()
      if (success) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()

        // formData.append('usr', this.MXN)
        formData.append('er', this.USD)

        try {
          await this.updateExchange({ formData })
          this.showToast(
            'Tipo de cambio actualizado, correctamente',
            `Precio de dolar actualizado a $${this.USD}MXN`,
            'success',
          )
          this.upExchange = false
          await this.loadExchange()
          this.$swal.close()
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },

    /**
    * Validador de totales
    * @summary Valida que el valor sea nulo, en caso de serlo devuelve N/A, en caso contrario
    * devuelve el valor formateado como moneda
    * @param {Number} value - Valor a validar
    */
    validateCurrency(value) {
      if (value === 'Por definir') {
        return 'Por definir'
      }

      const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })

      return USDollar.format(value)
    },

    formatPrice(value) {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g
      const rep = '$1,'
      return value.replace(exp, rep)
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
    onClose() {
      this.USD = this.statePrice
      if (this.USD) {
        this.upExchange = false
      } else {
        this.exchange = false
      }
    },
    listenerData(response) {
      const changeDataService = response.data.data[0]?.Ecomm_exchange_rate
      if (changeDataService) {
        this.exchange = true
        return changeDataService
      }
      this.noExchange = true
      return ''
    },
    onUpdate() {
      this.statePrice = this.USD
      this.exchange = true
      this.upExchange = true
    },
  },
}
</script>
<style lang="scss" scoped>
.hero-change{
  padding: 16px 24px 16px 24px!important;
  h3{
    font-weight: 600;
    color: #000;
    font-size: 18px;
    font-style: normal;
    line-height: 24px;
  }
}
.body-padding-change{
  padding: 16px 24px 24px 24px;
  h1{
    font-size: 32px;
    color: var(--Pressed, #0D1B2C);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.btn-add-change{
  display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    gap: 8px;
}
.box-flag{
  display: flex;
}
.no-change{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  gap: 8px;
  padding: 16px 0 32px
  }
.no-change > .image-icon{
  width: 60px;
  height: 60px;
}
.icon-equal{
  margin-top: 30px;
  font-size: 24px;
}
button{
  padding-left: 12px!important;
  padding-right: 12px!important;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1.2px;
}
</style>
