<template>
  <div>

    <!-- #region::Searcher, filters and actions buttons -->
    <EcommerceListFilters
      :title="title"
      :is-circuits="isCircuitTab(title)"
      @on:filter="handleFilter"
    />
    <!-- #endregion::Searcher, filters and actions buttons -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingProducts">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Products list table -->
          <b-table
            v-if="availableProducts"
            class="my-0 table-product-ecom"
            :class="`${filteredSortBy === '' ? 'qty_desc' : filteredSortBy}`"
            :data-table-circuit="isCircuitTab(title)"
            responsive
            :items="products"
            :fields="columns"
            :tbody-tr-class="''"
            :no-local-sorting="true"
            @sort-changed="sortingChanged"
          >
            <!-- #region: column for image -->
            <template #cell(img)="{ item }">
              <CreateCollageVue
                v-if="isCircuitTab(title)"
                :images="item.images"
              />
              <b-img
                v-else
                height="50"
                :src="item.image"
              />
            </template>
            <!-- #endregion: column for image -->

            <!-- #region: column for Name -->
            <template #cell(ID)="{ item }">
              <p
                v-if="(isCircuitTab(title))"
                class="mb-0"
              >
                {{ item.Name }}
              </p>
              <router-link
                v-else
                :to="{ name: 'product-details', params: { id: item.IdProduct } }"
                target="_blank"
              >
                {{ item.Name }}
              </router-link>
            </template>
            <!-- #endregion: column for Name -->
            <!-- #region: column for Descripcion -->
            <template #cell(Description)="{ item }">
              {{ item.Description }}
            </template>
            <!-- #endregion: column for Descripcion -->
            <!-- #region: column for Prodcutos solo aplica para circuitos-->
            <template
              #cell(products)="{ item }"
            >
              {{ item.products }}
            </template>
            <!-- #endregion: column for Prodcutos -->
            <!-- #region: column for Qty -->
            <template #cell(Qty)="{ item }">
              {{ item.Qty }}
            </template>
            <!-- #endregion: column for Qty -->
            <!-- #region: column for brand -->
            <template #cell(brand)="{ item }">
              {{ item.brand }}
            </template>
            <!-- #endregion: column for brand -->
            <!-- #region: column for model -->
            <template #cell(Model)="{ item }">
              {{ item.Model }}
            </template>
            <!-- #endregion: column for model -->
            <!-- #region: column for category -->
            <template #cell(category)="{ item }">
              {{ item.category }}
            </template>
            <!-- #endregion: column for category -->
            <!-- #region: column for subcategory -->
            <template #cell(subcategory)="{ item }">
              {{ item.subcategory }}
            </template>
            <!-- #endregion: column for subcategory -->
            <!-- #region: column for Code -->
            <template #cell(Code)="{ item }">
              #{{ item.Code }}
            </template>
            <!-- #endregion: column for Code -->
            <!-- #region: column for Price -->
            <template #cell(Price)="{ item }">
              <div class="box__price-ecom">
                <span v-if="isCircuitTab(title)">{{ validateCurrency(item.priceMin) }}</span>
                <span>{{ validateCurrency(item.Price) }}</span>
              </div>
            </template>
            <!-- #endregion: column for Price -->
            <!-- #region: column for Ecommerce -->
            <template #cell(Ecommerce)="{ item }">
              <CheckInput
                :is-active="item.itsActiveForEcomm === 1"
                :product-id="isCircuitTab(title) ? item.IdCircuit : item.IdProduct"
                :is-circuit="isCircuitTab(title)"
                :is-disabled="isCircuitTab(title) && !item.products"
                :type-check="'switch'"
              />
            </template>
            <!-- #endregion: column for Ecommerce -->
            <!-- #region: column for offert -->
            <template #cell(OfferPrice)="{ item }">
              <UpdateOffert
                :product-id="item[typeID]"
                :is-circuit="isCircuitTab(title)"
                :offert-value="item.OfferPrice"
                :show-price-ecom="item.hiddenOfferPrice"
                :offert-edit-active="offertEditActive"
                :price-max="item.Price"
                @on:newPriceOffert="changeOffert"
              />
            </template>
            <!-- #endregion: column for offert -->
            <!-- #region::A virtual column for actions -->
            <template #cell(actions)="{ item }">

              <div
                v-if="offertEditActive.id === item[typeID]"
                class="d-flex justify-content-start"
              >
                <b-button
                  v-b-tooltip.hover.top="'Actualizar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  @click="() => activeOffertUpdate({id: item[typeID], update:true})"
                >
                  <i class="fa-solid fa-check" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Cancelar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  @click="() => activeOffertUpdate({})"
                >
                  <i class="fa-solid fa-xmark" />
                </b-button>
              </div>
              <div
                v-else
                class="d-flex justify-content-start"
              >

                <!-- #region::edit product button -->
                <b-button
                  v-b-tooltip.hover.top="'Editar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  @click="() => activeOffertUpdate({id: item[typeID]})"
                >
                  <i><iconEdit /></i>
                </b-button>
                <!-- #endregion::edit product button -->

                <!-- #region:: show/hide product button -->
                <b-button
                  v-b-tooltip.hover.top="(item.hiddenOfferPrice ? 'Mostrar' : 'Ocultar') + ' precio'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle d-flex"
                >
                  <CheckInput
                    :is-active="item.hiddenOfferPrice === 1"
                    :product-id="isCircuitTab(title) ? item.IdCircuit : item.IdProduct"
                    :is-circuit="isCircuitTab(title)"
                    :type-check="'show'"
                    @on:tooltip="changeChecked"
                  />
                </b-button>
                <!-- #endregion:: show/hide product button -->
                <!-- #endregion:: offert active product button -->
                <b-button
                  v-b-tooltip.hover.top="(item.featured ? 'Definir como no' : 'Definir como') + ' destacado'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle  d-flex "
                >
                  <CheckInput
                    :is-active="item.featured === 1"
                    :product-id="isCircuitTab(title) ? item.IdCircuit : item.IdProduct"
                    :is-circuit="isCircuitTab(title)"
                    :type-check="'onOffert'"
                  />

                </b-button>
              </div>
            </template>
            <!-- #endregion::A virtual column for actions -->
          </b-table>
          <!-- #endregion::Products list table -->

          <!-- #region::Alert when no transfers are available -->
          <b-alert
            v-if="!availableProducts && !isLoadingProducts"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron {{ title }}.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no transfers are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableProducts"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalProducts"
      :module="'ecommerce'"
    />
    <!-- #endregion end::Pagination & items per list -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BSkeleton, BCard, BCardText, BImg, BAlert, VBTooltip, BTable, BButton,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'

import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import EcommerceListFilters from '@/modules/ecommerce/products/components/EcommerceListFilters.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'

import suppliersListTooltips from '@/helpers/SuppliersListTooltips'
import IconEdit from '@/modules/ecommerce/products/components/icons/iconEdit.vue'
import CheckInput from '@/modules/ecommerce/products/components/form/CheckInput.vue'
import UpdateOffert from '@/modules/ecommerce/products/components/form/UpdateOffert.vue'
import CreateCollageVue from '@/modules/ecommerce/products/components/collage/CollageCircuits.vue'
// #endregion

export default {
  components: {
    BImg,
    BCard,
    BAlert,
    BSkeleton,
    BCardText,
    BTable,
    BasicPaginator,
    EcommerceListFilters,
    BButton,
    IconEdit,
    CheckInput,
    CreateCollageVue,
    UpdateOffert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    /**
     * Titulo que se muestra en la pestaña
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Indica el tipo de estatus de los productos
     */
    typeProducts: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lastPage: 0,
      totalProducts: 0,
      columns: [
        {
          key: 'img',
          label: 'Imagen',
          thStyle: 'text-transform: none; ',
          thClass: 'bg-light td-ecom',
          tdClass: '',
        },
        {
          key: 'ID',
          label: 'Nombre',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'Description',
          label: 'Descripción',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'Qty',
          label: 'Cantidad',
          sortable: true,
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'brand',
          label: 'Marca',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'Model',
          label: 'Modelo',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'category',
          label: 'Categoría',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'subcategory',
          label: 'Subcategoria',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'Code',
          label: 'Código',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'Price',
          label: 'Precio',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'Ecommerce',
          label: 'Mostrar en sitio',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
          tdClass: 'check__box-ecom',
        },
        {
          key: 'OfferPrice',
          label: 'Precio oferta',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },
        {
          key: 'actions',
          label: '',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light td-ecom',
        },

      ],
      products: [],
      isLoadingProducts: false,
      totalSale: 0,
      totalCost: 0,
      areAllColumnsHidden: false,
      tooltipsTexts: suppliersListTooltips,
      offertEditActive: {
        active: false,
        id: null,
        update: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      getListsParams: 'ecommerce/getListsParams',
      /**
       * Filtros globales
       */
      getFilteredPriceEcom: 'filters/getFilteredPriceEcom',
      getFilteredDescription: 'filters/getFilteredDescription',
      getFilteredFavorite: 'filters/getFilteredFavorite',
      getFilteredSortBy: 'filters/getFilteredSortBy',
      getFilteredBrand: 'filters/getFilteredBrand',
      getFilteredStatus: 'filters/getFilteredStatus',
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredSubcategory: 'filters/getFilteredSubcategory',
      getFilteredCategory: 'filters/getFilteredCategory',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
    }),
    /**
     * Listas de parametros de filtrado de pestañas
     */
    listsParams: {
      get() { return this.getListsParams },
    },
    /**
    /**
     * Marca filtrada (filtro en store)
     */
    filteredBrand: {
      get() { return this.getFilteredBrand },
      set(value) { this.setFilteredBrand(value) },
    },
    /**
     * Filtro de estatus (filtro en store)
     */
    filteredStatus: {
      get() { return this.getFilteredStatus },
      set(value) { this.setFilteredStatus(value) },
    },
    /**
     * Filtro de precio (filtro en store)
     */
    filteredPriceEcom: {
      get() { return this.getFilteredPriceEcom },
      set(value) { this.setFilteredPriceEcom(value) },
    },
    /**
     * Filtro de descrpcion (filtro en store)
     */
    filteredDescription: {
      get() { return this.getFilteredDescription },
      set(value) { this.setFilteredDescription(value) },
    },
    /**
     * Filtro de producto destacado (filtro en store)
     */
    filteredFavorite: {
      get() { return this.getFilteredFavorite },
      set(value) { this.setFilteredFavorite(value) },
    },
    /**
     *filtro para ordenar  (filtro en store)
     */
    filteredSortBy: {
      get() { return this.getFilteredSortBy },
      set(value) { this.setFilteredSortBy(value) },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Subcategoría filtrada (filtro en store)
     */
    filteredSubcategory: {
      get() { return this.getFilteredSubcategory },
      set(value) { this.setFilteredSubcategory(value) },
    },
    /**
     * Categoría filtrada (filtro en store)
     */
    filteredCategory: {
      get() { return this.getFilteredCategory },
      set(value) { this.setFilteredCategory(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    availableProducts() {
      return this.totalProducts > 0
    },
    /**
     * Indica is el id es de producto o circuito
     */
    typeID() {
      if (this.isCircuitTab(this.title)) {
        return 'IdCircuit'
      }
      return 'IdProduct'
    },
  },

  /**
   * Hook que se ejecuta cuando el componente es montado
   * @summary Si la lista ya ha sido cargada previamente, se cargan las columnas
   * que han sido seleccionadas desactivadas
   */
  mounted() {
    this.typeColumnForCircuits()
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  * @summary Carga la lista de traspasos
  */
  async created() {
    await this.loadProductsListWithVerification()
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts',
      loadCircuits: 'ecommerce/loadCircuits',

      /**
       * Filtros globales
       */
      setFilteredBrand: 'filters/setFilteredBrand',
      setFilteredStatus: 'filters/setFilteredStatus',
      setFilteredPriceEcom: 'filters/setFilteredPriceEcom',
      setFilteredDescription: 'filters/setFilteredDescription',
      setFilteredFavorite: 'filters/setFilteredFavorite',
      setFilteredSortBy: 'filters/setFilteredSortBy',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredSubcategory: 'filters/setFilteredSubcategory',
      setFilteredCategory: 'filters/setFilteredCategory',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
    }),
    async changeChecked(check, id) {
      // eslint-disable-next-line no-unused-expressions
      this.products?.forEach(product => {
        if (product[this.typeID] === id) {
          // eslint-disable-next-line no-param-reassign
          product.hiddenOfferPrice = check ? 1 : 0
        }
      })
    },
    async changeOffert(offert, id) {
      this.activeOffertUpdate({})
      // eslint-disable-next-line no-unused-expressions
      this.products?.forEach(product => {
        if (product[this.typeID] === id) {
          // eslint-disable-next-line no-param-reassign
          product.OfferPrice = offert
        }
      })
    },
    /**
     * * escuchar sort
     *
    */
    sortingChanged() {
      // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
      // ctx.sortDesc ==> true if sorting descending, false otherwise
      this.filteredSortBy = (this.filteredSortBy === 'qty_desc' || this.filteredSortBy === ''
        ? 'qty_asc' : 'qty_desc')
      this.loadProductsListWithVerification()
    },
    /**
    * Evento de filtrado
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de productos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'brand': this.filteredBrand = filter.value
          break
        case 'subCategory': this.filteredSubcategory = filter.value
          break
        case 'category': this.filteredCategory = filter.value
          break
        case 'status': this.filteredStatus = filter.value
          break
        case 'price': this.filteredPriceEcom = filter.value && (filter.value === 'Visible' ? '1' : '0')
          break
        case 'description': this.filteredDescription = filter.value
          break
        case 'favorite': this.filteredFavorite = filter.value && (filter.value === 'Ver solo destacados' ? '1' : '0')
          break
        default: this.filteredSearchTerm = filter.value
          this.filteredCurrentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadProductsListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadProductsListWithVerification() {
      await this.loadProductsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadProductsList()
    },

    /**
    * Verificación de contenido
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.products.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableProducts) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadProductsList()
        }
      }
    },

    /**
    * Carga de registros de tipo productos
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de productos y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de traspasos⁡
    */
    async loadProductsList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingProducts = true

        const response = (this.title.includes('Productos') ? await this.loadProducts(this.loadParams()) : await this.loadCircuits(this.loadParams()))
        this.products = response.data.data.data
        this.totalProducts = response.data.data.total
        this.lastPage = response.data.data.last_page
        // this.tooltipShow = response.data.data.data.hiddenOfferPrice
        this.isLoadingProducts = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Parámetros de carga
    * @summary Devuelve los parámetros de carga de la lista. Verifica si la lista ya ha sido
    * previamente con parametros para la selección de columnas. Si no ha sido cargada previamente,
    * se cargan todas las columnas desde el store. En caso contrario, se cargan las columnas
    * relacionadas con la lista
    */
    loadParams() {
      return {
        to: 'erp',
        identifier: 'nETO7GNzO8xJpRL5CvJ4',
        status: this.typeProducts,
        title: this.title,
        perPage: this.filteredPerPage,
        brandTerm: this.filteredBrand,
        hidePrice: this.filteredPriceEcom,
        description: this.filteredDescription,
        favorite: this.filteredFavorite,
        searchTerm: this.filteredSearchTerm,
        currentPage: this.filteredCurrentPage,
        subCategoryTerm: this.filteredSubcategory,
        categoryTerm: this.filteredCategory,
        estatus: this.filteredStatus,
        orderBy: this.filteredSortBy === '' ? 'qty_desc' : this.filteredSortBy,
      }
    },

    /**
    * Validador de totales
    * @summary Valida que el valor sea nulo, en caso de serlo devuelve N/A, en caso contrario
    * devuelve el valor formateado como moneda
    * @param {Number} value - Valor a validar
    */
    validateCurrency(value) {
      if (value === 'Por definir') {
        return 'Por definir'
      }

      const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })

      return USDollar.format(value)
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
    /**
    * Muestra si la pestaña es de tipo circuito
    * @param {string} title - Título del tab
    */
    isCircuitTab(title) {
    // eslint-disable-next-line indent
    return title === 'Circuitos'
    },
    typeColumnForCircuits() {
      if (this.isCircuitTab(this.title)) {
        return this.columns.splice(2, 7,
          {
            key: 'products',
            label: 'Productos',
            sortable: true,
            thStyle: 'text-transform: none;',
            thClass: 'bg-light td-ecom',
          })
      }
      return this.columns
    },
    activeOffertUpdate({ id = '', update = false, active = false }) {
      this.offertEditActive = { id, active, update }
    },
  },
}
</script>

<style lang="scss">
.fa-check{
  font-size: 20px;
  color: #0CA852;
}
.fa-xmark{
  font-size: 20px;
  color: #FF7766;
}
.btn.btn-icon{
  padding: 0.715rem 0.436rem;
}
.btn-flat-secondary:hover:not(.disabled):not(:disabled) {
  background-color: transparent;
}
.btn-flat-secondary:active, .btn-flat-secondary.active, .btn-flat-secondary:focus{
  background-color: transparent;
}

.td-ecom{
  text-wrap: nowrap;
  //background-position: left 0.36rem center!important;
}
.box__price-ecom > span:nth-child(2)::before{
  content: "-";
  margin-right: 8px;
  margin-left: 8px;
}

.check__box-ecom > div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-product-ecom{
  table{
    tbody{
      tr{
        td{
          padding: 0.5rem 2rem;
        }
      }
    }
  }
 }
 .qty_desc>table>thead>tr>th:nth-child(4){
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6' stroke='%235E5873'/%3E%3Cpath d='M13 7L7 1 1 7' stroke='%23C6C4D0'/%3E%3C/g%3E%3C/svg%3E")!important;
  }
 .qty_desc>table[data-table-circuit='true']>thead>tr>th:nth-child(4){
    background-image: none!important;
  }
 .qty_desc>table[data-table-circuit='true']>thead>tr>th:nth-child(3){
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6' stroke='%235E5873'/%3E%3Cpath d='M13 7L7 1 1 7' stroke='%23C6C4D0'/%3E%3C/g%3E%3C/svg%3E")!important;
  }
  .qty_asc>table>thead>tr>th:nth-child(4){
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6' stroke='%23C6C4D0'/%3E%3Cpath d='M13 7L7 1 1 7' stroke='%235E5873'/%3E%3C/g%3E%3C/svg%3E")!important;
  }
 .qty_asc>table[data-table-circuit='true']>thead>tr>th:nth-child(4){
    background-image: none!important;
  }
 .qty_asc>table[data-table-circuit='true']>thead>tr>th:nth-child(3){
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26'%3E%3Cg stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 19l6 6 6-6' stroke='%23C6C4D0'/%3E%3Cpath d='M13 7L7 1 1 7' stroke='%235E5873'/%3E%3C/g%3E%3C/svg%3E")!important;
  }
</style>
