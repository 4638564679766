<template>
  <div>
    <SaveChangeMonyeForm />
  </div>
</template>

<script>
import SaveChangeMonyeForm from '@/modules/ecommerce/products/components/form/SaveChangeMonyeForm.vue'

export default {
  components: {
    SaveChangeMonyeForm,
  },

}
</script>

<style>

</style>
