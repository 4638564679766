<!-- eslint-disable vue/require-default-prop -->
<!-- eslint-disable vue/require-default-prop -->
<template>
  <div>
    <label
      data-checked="false"
      class="input--check"
      :data-switch="typeCheck"
    >
      <input
        v-model="checked"
        name="terms"
        type="checkbox"
        class="input__checkbox"
        :data-switch="typeCheck"
        :checked="checked"
        :disabled="isDisabled"
      >
      <span class="input__indicator" />
      <span class="input__text input__text--check" />
    </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  props: {
    /**
     * Indica  el tipo de input
     */
    typeCheck: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    productId: {
      type: Number,
      required: true,
    },
    isCircuit: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.isActive && !this.isDisabled,
      typeChecked: null,
      key: 'product',
      textForChecked: [],
    }
  },
  created() {
    if (this.isCircuit) {
      this.key = 'circuit'
    }
    if (this.typeCheck === 'switch') {
      this.typeChecked = 'active'
      this.textForChecked.positive = 'El producto ha sido activado'
      this.textForChecked.negative = 'El producto ha sido desactivado'
    }
    if (this.typeCheck === 'show') {
      this.typeChecked = 'hide_offer_price'
      this.textForChecked.positive = 'El precio del producto ha sido ocultado'
      this.textForChecked.negative = 'El precio del producto ha sido mostrado'
    }
    if (this.typeCheck === 'onOffert') {
      this.typeChecked = 'favorite'
      this.textForChecked.positive = 'El producto ha sido agregado a las ofertas'
      this.textForChecked.negative = 'El producto ha sido removido de las ofertas'
    }
  },
  methods: {
    ...mapActions({
      updateActive: 'ecommerce/updateActive',
    }),
    async onUpdateActive() {
      try {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()
        formData.append(this.key, this.productId)
        formData.append(this.typeChecked, this.checked ? 1 : 0)

        const response = await this.updateActive({
          key: this.key,
          formData,
        })
        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }
        console.log(response)

        if (response.status === 200) {
          this.showToast(
            'Producto actualizado',
            `${this.checked ? this.textForChecked.positive : this.textForChecked.negative} correctamente.`,
            'success',
          )
          this.$emit('on:tooltip', this.checked, this.productId)
        } else {
          this.checked = !this.checked
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    async checked(val) {
      await this.onUpdateActive()
      console.log(val)
      console.log(this.productId)
    },
  },
}
</script>

<style scoped lang="scss">
.input--check {
  position: relative;
  display: flex;

    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    margin-top: -2px;
}
.input--check[data-switch="switch"] {
  margin-top: 1px;
}
.input__checkbox{
  display: none;
}

.input__checkbox[data-switch="onOffert"] ~ .input__text--check:before {
  background-image: url("../../../../../assets/images/icons/offOfert.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.input__checkbox:checked[data-switch="onOffert"] ~ .input__text--check:before {
    background-image: url("../../../../../assets/images/icons/onOfert.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
.input__checkbox[data-switch="show"] ~ .input__text--check:before {
  background-image: url("../../../../../assets/images/icons/eye.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.input__checkbox:checked[data-switch="show"] ~ .input__text--check:before {
    background-image: url("../../../../../assets/images/icons/eye-off.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

.input--check[data-switch="switch"] {
    height: 14px;
  }

.input__checkbox[data-switch="switch"] ~ .input__text--check:before {
    background-image: none
  }

.input--check[data-switch="switch"] .input__checkbox {
    height: 0;
    position: absolute;
    display: flex;
}
.input--check[data-switch="switch"] .input__checkbox ~ .input__indicator {
  content: "";
  width: 40px;
  position: absolute;
  cursor: pointer;
  top: -4px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 20px;
  border-radius: 20px;
}
.input__text--check {
  display: inline;
  padding-left: 22px;
}

.input__text--check::before {
  position: absolute;
  content: "";
  height: 17px;
  width: 18px;
  top: 3px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: -22px;
}
.input--check[data-switch="switch"] .input__text--check {
  margin-left: 25px;
}
.input--check[data-switch="switch"] .input__text--check::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  top: -2px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-left: 0;
}
    .input--check[data-switch="switch"] .input__checkbox:checked ~ .input__indicator {
      background:#0053D3;
    }
    .input--check[data-switch="switch"] .input__checkbox:checked ~ .input__text--check::before {
      background-image: none;
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
</style>
