<template>
  <div class="content-image">

    <div
      class="image__box"
    >
      <img
        v-for="(img, i) in (arraySlicesImages)"
        :key="i"
        :datatype="img.link"
        :src="img.link"
        alt=""
        class="logos"
        :style="`width:calc(100% / ${grigCircuits.width }); height:calc(100% / ${ grigCircuits.height})`"
      >
    </div>

  </div>
</template>

<script>
export default {

  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      collage: this.images?.length > 1,
      cuantity_images: this.images?.length,
      arrayImagesCircuits: this.images,
      arraySlicesImages: [],
    }
  },

  computed: {
    grigCircuits() {
      if (this.arraySlicesImages.length === 1) {
        return {
          width: 1,
          height: 1,
        }
      }
      if (this.arraySlicesImages.length === 2) {
        return {
          width: 2,
          height: 1,
        }
      }
      if (this.arraySlicesImages.length === 3 || this.arraySlicesImages.length === 4) {
        return {
          width: 2,
          height: 2,
        }
      }
      if (this.arraySlicesImages.length === 5 || this.arraySlicesImages.length === 6) {
        return {
          width: 2,
          height: 3,
        }
      }
      if (this.arraySlicesImages.length > 6 && this.arraySlicesImages.length < 10) {
        return {
          width: 3,
          height: 3,
        }
      }
      if (this.arraySlicesImages.length > 9) {
        return {
          width: 4,
          height: 3,
        }
      }
      return {
        width: 1,
        height: 1,
      }
    },
  },

  created() {
    this.arraySlicesImages = this.arraySlices()
  },

  methods: {
    arraySlices() {
      if (this.cuantity_images < 12) {
        return this.arrayImagesCircuits
      }
      return this.arrayImagesCircuits.slice(0, 12)
    },
  },
}
</script>

<style lang="scss">
.image__box{
  width: 70px;
  height: 70px;
  img{
    object-fit: cover;
  }
}

</style>
