<template>
  <div>
    <!-- #region::Skeleton -->
    <template v-if="isLoadingEcommerceData">
      <BasicSkeleton height="200px" />
      <BasicSkeleton height="100px" />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>

      <!-- #endregion::Multiple supplier selector -->

      <!-- #region::Products tabs -->
      <b-tabs
        v-if="$ability.can('read without_suppliers', 'Reports')"
        v-model="selectedIndex"
        @changed="onTabChanged"
      >
        <b-tab
          v-for="tab in tabs"
          :key="tab.value"
          lazy
          :title="tab.title"
          @click="onSelectedTab(tab.title)"
        >
          <div v-if="tab.title === 'Tipo de cambio'">
            <ChangeMoney />
          </div>
          <EcommerceList
            v-else
            :title="tab.title"
            :type-products="tab.status"
          />
        </b-tab>
        <!-- #endregion::Products tabs -->

      </b-tabs>

    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BTabs, BTab,
} from 'bootstrap-vue'

import EcommerceList from '@/modules/ecommerce/products/components/EcommerceList.vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import ChangeMoney from '@/modules/ecommerce/products/views/ChangeMoney.vue'

export default {
  components: {
    BTabs,
    BTab,
    BasicSkeleton,
    EcommerceList,
    ChangeMoney,
  },
  data() {
    return {
      selectedIndex: 0,
      isLoadingEcommerceData: false,
    }
  },
  computed: {
    ...mapGetters({
      getTabs: 'ecommerce/getTabs',
      getListsParams: 'ecommerce/getListsParams',
      /**
       * Filtros globales
       */
      /**
       * Filtros globales
       */
      getFilteredBrand: 'filters/getFilteredBrand',
      getFilteredStatus: 'filters/getFilteredStatus',
      getFilteredPriceEcom: 'filters/getFilteredPriceEcom',
      getFilteredDescription: 'filters/getFilteredDescription',
      getFilteredFavorite: 'filters/getFilteredFavorite',
      getFilteredSortBy: 'filters/getFilteredSortBy',
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredSubcategory: 'filters/getFilteredSubcategory',
      getFilteredCategory: 'filters/getFilteredCategory',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
    }),
    /**
     * Listas de pestañas
     */
    tabs: {
      get() { return this.getTabs },
    },
    /**
     *  * Listas de parametros de filtrado de pestañas
     */
    listsParams: {
      get() { return this.getListsParams },
    },
    /**
     * Marca filtrada (filtro en store)
     */
    filteredBrand: {
      get() { return this.getFilteredBrand },
      set(value) { this.setFilteredBrand(value) },
    },
    /**
     * status filtrado (filtro en store)
     */
    filteredStatus: {
      get() { return this.getFilteredStatus },
      set(value) { this.setFilteredStatus(value) },
    },
    /**
     *precio oferta filtrado (filtro en store)
     */
    filteredPriceEcom: {
      get() { return this.getFilteredPriceEcom },
      set(value) { this.setFilteredPriceEcom(value) },
    },
    /**
     * Filtro de descrpcion (filtro en store)
     */
    filteredDescription: {
      get() { return this.getFilteredDescription },
      set(value) { this.setFilteredDescription(value) },
    },
    /**
     * Filtro de producto destacado (filtro en store)
     */
    filteredFavorite: {
      get() { return this.getFilteredFavorite },
      set(value) { this.setFilteredFavorite(value) },
    },
    /**
     *filtro para ordenar  (filtro en store)
     */
    filteredSortBy: {
      get() { return this.getFilteredSortBy },
      set(value) { this.setFilteredSortBy(value) },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Subcategoría filtrada (filtro en store)
     */
    filteredSubcategory: {
      get() { return this.getFilteredSubcategory },
      set(value) { this.setFilteredSubcategory(value) },
    },
    /**
     * Categoría filtrada (filtro en store)
     */
    filteredCategory: {
      get() { return this.getFilteredCategory },
      set(value) { this.setFilteredCategory(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
  },
  async created() {
    this.isLoadingEcommerceData = true
    await this.loadBrandsWithoutPagination(true)
    await this.loadCategoriesWithoutPagination(true)
    await this.categoriesWithoutPagination(true)

    this.isLoadingEcommerceData = false
  },
  methods: {
    ...mapActions({
      removeListParams: 'ecommerce/removeListParams',
      loadBrandsWithoutPagination: 'brands/loadBrandsWithoutPagination',
      loadCategoriesWithoutPagination: 'categories/loadCategoriesWithoutPagination',
      categoriesWithoutPagination: 'ecommerce/loadCategoriesWithoutPagination',
      /**
       * Filtros globales
       */
      setFilteredBrand: 'filters/setFilteredBrand',
      setFilteredStatus: 'filters/setFilteredStatus',
      setFilteredPriceEcom: 'filters/setFilteredPriceEcom',
      setFilteredDescription: 'filters/setFilteredDescription',
      setFilteredFavorite: 'filters/setFilteredFavorite',
      setFilteredSortBy: 'filters/setFilteredSortBy',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredSubcategory: 'filters/setFilteredSubcategory',
      setFilteredCategory: 'filters/setFilteredCategory',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
    }),

    /**
    * Evento de selección de pestaña
    * @summary Establece los filtros de búsqueda de la pestaña seleccionada. Si la pestaña ya ha sido
    * seleccionada anteriormente, se establecen los filtros de búsqueda de la pestaña seleccionada, de
    * lo contrario, se establecen los filtros de búsqueda por defecto
    * @param {String} title - Título de la pestaña seleccionada
    */
    onSelectedTab(title) {
      const params = this.listsParams.find(item => item.title === title)
      this.filteredPerPage = params?.perPage || 10
      this.filteredBrand = params?.brandTerm || ''
      this.filteredStatus = params?.estatus || ''
      this.filteredSearchTerm = params?.searchTerm || ''
      this.filteredCurrentPage = params?.currentPage || 1
      this.filteredSubcategory = params?.subCategoryTerm || ''
      this.filteredCategory = params?.categoryTerm || ''
      this.filteredPriceEcom = params?.hidePrice || ''
      this.filteredDescription = params?.description || ''
      this.filteredFavorite = params?.favorite || ''
      this.filteredSortBy = params?.orderBy || ''
    },

    /**
    * Selección de pestaña
    * @summary Establece el índice de la pestaña seleccionada cuando se agrega un
    * nuevo proveedor. Hace excepción con la pestaña "Sin proveedor". Evita que se
    * establezca el índice de la pestaña cuando se elimina un proveedor
    * @param {Array} currentTabs - Lista de pestañas actuales
    * @param {Array} previousTabs - Lista de pestañas anteriores
    */
    onTabChanged(currentTabs, previousTabs) {
      // Verifica que la cantidad de pestañas sea mayor a 2 y que la cantidad de pestañas anteriores
      // sea menor o igual a la cantidad de pestañas actuales para establecer el índice de la pestaña
      if (this.tabs.length > 2 && previousTabs.length > 2 && previousTabs.length <= currentTabs.length) {
        this.selectedIndex = this.tabs.length - 1
      }
    },
  },
}

</script>
