<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 21"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9997 0.899963L11 2.89996L13.999 5.90099L16 3.89996L12.9997 0.899963ZM0 13.9L9.99971 3.89996L13 6.89996L3 16.9H0V13.9ZM1 18.9H15C15.5523 18.9 16 19.3477 16 19.9C16 20.4522 15.5523 20.9 15 20.9H1C0.447715 20.9 0 20.4522 0 19.9C0 19.3477 0.447715 18.9 1 18.9Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>
