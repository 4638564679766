<!-- eslint-disable vue/require-default-prop -->
<!-- eslint-disable vue/require-default-prop -->
<template>
  <div style="position:relative">
    <div
      v-if="!!activeUpdate"
      class="input__disable"
    />
    <label
      class="input input__box-offert"
    >
      <TextInputWithValidation
        v-model="offert"
        :data-show-ecom="showPriceEcom"
        :data-indefinded="!!!offert || offert === 0"
        vid="MXN"
        type="text"
        label=""
        name="Oferta"
        placeholder="Por definir"
        :rules="{regex: /^\d{1,3}(,\d{3})*(\.\d{1,2})?$/}"
        :readonly="!!activeUpdate"
      />
      <p
        v-if="validateMAx"
        class="input__box-offert--text-danger"
      >
        El precio oferta es invalido
      </p>
    </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required,
} from '@validations'
import { localize } from 'vee-validate'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import es from 'vee-validate/dist/locale/es.json'

export default {
  components: {
    TextInputWithValidation,
  },

  props: {
    /**
     * Indica  el tipo de input
     */

    productId: {
      type: Number,
      required: true,
    },
    isCircuit: {
      type: Boolean,
      required: true,
    },
    offertValue: {
      type: [String, Number],
      default: '',
    },
    offertEditActive: {
      type: Object,
      default: null,
    },
    priceMax: {
      type: [Number, String],
      default: 0,
    },
    showPriceEcom: {
      type: [Boolean, Number],
      default: false,
    },
  },
  data() {
    return {
      required,
      offert: this.offertValue ? this.formatPrice(String(this.offertValue)) : this.offertValue,
      typeChecked: null,
      key: 'product',
      textForChecked: [],
      offertDefault: this.offertValue,
      offertUpdate: null,
      activeUpdate: true,
    }
  },
  computed: {
    updateValue() {
      return this.offertEditActive?.id === this.productId
    },
    validateMAx() {
      return (this.offert ? Number(String(this.offert)?.replace(/,/g, '')) : 0) > (this.priceMax - 1)
    },
    valueInitOffert() {
      return this.offertValue - 1
    },
  },
  created() {
    localize('es', es)
    if (this.isCircuit) {
      this.key = 'circuit'
    }
  },
  methods: {
    ...mapActions({
      updatePriceOffert: 'ecommerce/updatePriceOffert',
    }),
    onUpdateOffert() {
      this.onUpdateActive()
    },
    formatPrice(value) {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g
      const rep = '$1,'
      return value.replace(exp, rep)
    },
    async onUpdateActive() {
      try {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()
        // formData.append(this.key, this.productId)
        this.offertUpdate = this.offert
        formData.append('offer_price', this.offert ? this.offert?.replace(/,/g, '') : 0)

        const response = await this.updatePriceOffert({
          key: this.key,
          id: this.productId,
          formData,
        })

        if (response.status === 200) {
          if (response.data.errors?.offer_price || null) {
            this.showToast('Error de validación', response.data?.errors.offer_price[0], 'danger')
            return
          }
          this.showToast('Precio actualizado', 'El precio se ha actualizado correctamente', 'success')
          this.offert = this.offertUpdate
          this.$emit('on:newPriceOffert', this.offert, this.productId)
        } else {
          console.log('error', response)
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    async offertValue(val) {
      // await this.onUpdateActive()
      console.log(val)
      this.offert = val
      this.offertDefault = val
      this.activeUpdate = true
      // console.log(this.productId)
    },
    offertEditActive(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        if (this.updateValue) {
          this.activeUpdate = false
          // eslint-disable-next-line no-unused-expressions
          if (val?.update && !this.validateMAx) this.onUpdateActive()
        } else {
          this.offert = this.offertDefault
          this.activeUpdate = true
        }
      }
    },
    offert(value) {
      if (value) {
        this.offert = this.formatPrice(String(value)?.replace(/,/g, '')) || 0
      }
    },
  },
}
</script>

<style lang="scss">
.input{
position: relative;
justify-content: flex-start;
align-items: center;
display: flex;
width: 120px;
&__disable{
  width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    position: absolute;
    z-index: 1;
  &+.input>span[name="Oferta"]{
    .input-group{
      &::before{
        content:"$";
        font-size: 16px;
        color: #FF7766;
        position: absolute;
        bottom: 9px;
        left: -3px;
      }
    }
  }
}
&>span[name="Oferta"]{
  input{
    width: 100%;
    padding: 2px 8px;
    border-radius: 5px;
    color: #FF7766;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #aaaaaa;
  }
  input[readonly]{
    border: none;
    background: transparent;
  }
  input:focus-visible, input:focus, input:focus-within {
      transition: none!important;
      border: 1px solid #aaaaaa;
  }
  input:-internal-autofill-selected {
    background-color: transparent !important;
    background-image: none !important;
    color: var(--text-color) !important;
  }
  input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  input:focus-visible {
      outline:none!important;
  }
}
&>span[data-show-ecom="1"]{
  input{
    opacity: 0.5!important;
  }
  .input-group{
    &::before{
     opacity: 0.5!important;
    }
  }
}
&>span[data-indefinded="true"]{
  .input-group{
    &::before{
      content: ''!important;
    }
  }
}
&__box-offert{
  display: flex;
  flex-direction: column;
  &--text-danger{
    position: absolute;
    bottom: -29%;
    margin-bottom: 0;
    left: 0;
    color: #ff7766 !important;
    background: #ffeeec;
    padding: 4px;
    border-radius: 5px;
    line-height: 1.2rem;
  }
}
}

</style>
